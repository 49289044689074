#wpadminbar #wp-admin-bar-wp-logo .ab-item:first-child {
    padding: 0;
}
#wpadminbar #wp-admin-bar-wp-logo .ab-sub-wrapper {
	margin-left: -1px;
}
#wpadminbar #wp-admin-bar-wp-logo .ab-sub-wrapper .ab-item {
    padding: 0 7px;
}
#wpadminbar #custom-admin-logo span.infoicon-icon,
#wpbody #custom-dashboard-header span.infoicon-icon {
    padding: 0;
    margin: 0;
    display: block;
    color: #f7403a;
    background: #fff;
}
#wpadminbar #custom-admin-logo span.infoicon-icon {
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 1;
}
#wpbody #custom-dashboard-header span.infoicon-icon {
    float: left;
    margin: 2px 5px 0 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
}

// #wpfooter span.infoicon-logo {
//     padding: 0;
//     margin: 0;
//     display: block;
//     color: #f7403a;
//     background-image: url(../img/infomedia-logo-red.svg);
// }
#wpfooter #custom-footer-link span.infoicon-logo {
	height: 16px;
	line-height: 16px;
	font-size: 90px;
	overflow: hidden;
    width: 90px;
    padding: 0;
    margin: 0;
    display: block;
    color: #f7403a;
    background-image: url(../img/infomedia-logo-red.svg);
    background-repeat: no-repeat;
}
// #wpfooter #custom-footer-link span.infoicon-logo:before {
//     line-height: 16px;
// }

/* ------------------------------------------------------------
Infomedia Branding Icon Font Styles
------------------------------------------------------------ */

@charset "UTF-8";

@font-face {
  font-family: "infomedia-branding";
  src:url("../fonts/infomedia-branding.eot");
  src:url("../fonts/infomedia-branding.eot?#iefix") format("embedded-opentype"),
    url("../fonts/infomedia-branding.woff") format("woff"),
    url("../fonts/infomedia-branding.ttf") format("truetype"),
    url("../fonts/infomedia-branding.svg#infomedia-branding") format("svg");
  font-weight: normal;
  font-style: normal;

}

/* [data-icon]:before {
  font-family: "infomedia-branding" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

#wpadminbar #custom-admin-logo span.infoicon-icon:before,
#wpbody #custom-dashboard-header span.infoicon-icon:before {
//#wpfooter #custom-footer-link span.infoicon-logo:before {
  font-family: "infomedia-branding" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wpadminbar #custom-admin-logo span.infoicon-icon:before,
#wpbody #custom-dashboard-header span.infoicon-icon:before {
  content: "a";
}
// #wpfooter #custom-footer-link span.infoicon-logo:before {
//   content: "b";
// }



/* ------------------------------------------------------------
Dashboard Admin Settings
------------------------------------------------------------ */
#dashboard-admin {
    /* padding: 50px; */
    background: #eaeaea;
    font-family: 'Open Sans', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}
#dashboard-admin section div {
    /* margin-bottom: 25px; */
}
#dashboard-admin h2 {
    background: #dadada;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}
#dashboard-admin h4 {
    margin-bottom: 0;
}
#dashboard-admin .dev-notes {
    background: #00000080;
    color: white;
    padding: 10px;
    display: inline-block;
}

#dashboard-admin .field-description-wrap {
    display: flex;
    width: 100%;
    align-items: center;
}
#dashboard-admin .form-field {
    width: 40%;
    padding: 8px 0;
}
#dashboard-admin .field-description {
    width: 60%;
    border-left: 1px solid #cacaca;
    padding: 8px 25px;
    font-size: 14px;
}
#dashboard-admin label {
    font-size: 14px;
}
#dashboard-admin #color-second {
    margin-left: 15px;
}

#dashboard-admin #notices {
    margin-bottom: 40px;
}
#dashboard-admin .notice {
    padding: 10px 10px;
    border-radius: 4px;
    border-left: 8px solid #8e8e8e;
    background: #dcdcdc;
    margin-bottom: 10px;
}
#dashboard-admin .notice .icon {
    margin-right: 8px;
}
#dashboard-admin .notice.repo {
    border-color: #0747a6;
    background: rgba( 7, 71, 166, 20%);
}
#dashboard-admin .notice.danger {
    border-color: #f51100;
    background: rgba(245, 17, 0, 20% );
}
#dashboard-admin .notice.warning {
    border-color: #f5af00;
    background: rgba(245, 175, 0, 20% );
}

#adminmenu #toplevel_page_infodash-settings .wp-menu-image img {
    background: white;
    width: 22px;
    height: 22px;
    padding: 0;
    opacity: 1;
}
#adminmenu #toplevel_page_infodash-settings .wp-menu-image {
    display: flex;
    justify-content: center;
    align-items: center;
}
#adminmenu #toplevel_page_infodash-settings a {
    display: flex;
    align-items: center;
}
#adminmenu #toplevel_page_infodash-settings .wp-menu-name {
    padding-left: 0;
}
